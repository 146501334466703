import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import cx from "classnames";
import SEO from "../components/seo";

const useStyles = makeStyles(theme => ({
  backgroundColor: {
    backgroundColor: theme.palette.secondary.main
  },
  flex: {
    flex: 1
  },
  flexWrapCenter: {
    alignItems: "end",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  paddingAll: {
    padding: "70px 15px"
  },
  textBlockContent: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxWidth: 960,
    width: "100%"
  },
  textWhiteCentered: {
    color: "#fff",
    textAlign: "center"
  }
}));

function Contact() {
  const classes = useStyles();

  return (
    <>
      <SEO title={"Kontakt"} />
      <div
        className={cx(
          classes.paddingAll,
          classes.backgroundColor,
          classes.flex
        )}
      >
        <div className={classes.textBlockContent}>
          <Typography className={classes.textWhiteCentered} variant="headline">
            Kontakt
          </Typography>
          <Typography className={classes.textWhiteCentered} variant="body2">
            Vollkorn Games GbR
          </Typography>
          <Typography className={classes.textWhiteCentered} variant="body1">
            Günther-Wagner-Allee 29
          </Typography>
          <Typography className={classes.textWhiteCentered} variant="body1">
            30177 Deutschland
          </Typography>
          <a href="mailto:info@vollkorn.games">
            <Typography className={classes.textWhiteCentered} variant="body1">
              info@vollkorn.games
            </Typography>
          </a>
        </div>
      </div>
    </>
  );
}

export default Contact;
